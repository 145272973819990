body {
    background: #fafafa;
    font-family: "Open Sans", sans-serif;
    color: #333;
}

/* ==========================================================================
re-define navbar styles to match OGR ones
========================================================================== */

.navbar {
    background: #6e164e;
    height: 70px;
    text-transform: uppercase;
    font-size: 12px;
    z-index: 999;
}

.navbar-default {
    padding-top: 10px;
}

.navbar-default .navbar-nav .open .dropdown-menu {
    font-size: 12px;
}

.navbar-default .navbar-nav {
    position: relative;
    z-index: 999;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: white;
    background-color: transparent;
    box-shadow: inset 0 6px 0 0 #560939;
    transition: box-shadow 200ms ease, color 100ms ease;
}

.navbar-default .navbar-nav > li > a:hover {
    color: white;
    background-color: transparent;
    box-shadow: inset 0 6px 0 0 #5c6267;
    transition: box-shadow 200ms ease, color 100ms ease;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu {
    color: #fff;
    background: #6e164e;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background: #560939;
}

.navbar-default .navbar-nav .open .dropdown-menu > .active > a {
    color: #fff;
    background: #5c6267;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    background: #6e164e;
}

.navbar-default .navbar-toggle .icon-bar {
    background: #fff;
}

.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: #6e164e;
    border-width: 0;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus,
    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:hover,
    .navbar-default .navbar-nav > .open > a:focus {
        color: white;
        background: #560939;
    }

    .navbar-default .navbar-nav > li > a:hover {
        color: white;
        background: #5c6267;
    }
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Version number in footer
========================================================================== */
.footer-version {
    font-size: 12px;
    color: #b7b7b7; /* #ccc; */
    text-align: center;
}

.ogresearch-footer {
    font-size: 12px;
    /*color: #b7b7b7; !* #ccc; *!*/
    text-align: center;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo and headers styles
========================================================================== */
.navbar-brand.logo {
    position: absolute;
    display: block;
}

.logo .logo-img {
    height: 50px;
    display: inline-block;
    padding-right: 15px;
    vertical-align: middle;
    /*padding-bottom: 35px;*/
    margin-left: -40px;
    margin-top: -20px;
}

.logo .logo-text {
    color: white;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: bold;
}

.page-header {
    margin: 20px 0 20px;
}

.forecast-header {
    float: left;
    font-size: 14px;
    text-align: left;
}

.custom-link {
    color: #560939;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
}

.custom-link:hover {
    color: #560939;
    text-decoration: none;
}

.custom-link-iris {
    color: #560939;
    font-weight: 600;
    text-decoration: underline;
}

.custom-link-iris:hover {
    color: #560939;
    text-decoration: none;
}

.download-link {
    color: #5c6267;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
}

.download-link:hover {
    color: #5c6267;
    text-decoration: none;
}

.download-link-iris {
    color: #5c6267;
    font-weight: 600;
    text-decoration: underline;
}

.download-link-iris:hover {
    color: #5c6267;
    text-decoration: none;
}

.chart-tab {
    color: #560939;
}

.chart-tab:hover {
    color: #560939;
}

@media screen and (max-width: 992px) {
    .forecast-header {
        font-size: 12px;
    }
}

h1, h2, h3 {
    color: #560939;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

@media screen and (max-width: 568px) {
    .forecast-header {
        font-size: 12px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }
}

/* ==========================================================================
chart pane responsiveness
========================================================================== */

.chart-pane {
    height: 550px;
}

@media screen and (max-width: 1200px) {
    .chart-pane {
        height: 550px;
    }
}

@media screen and (max-width: 992px) {
    .chart-pane {
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .chart-pane {
        height: 300px;
    }
}

.projections-chart-pane {
    height: 400px;
}

@media screen and (max-width: 1200px) {
    .projections-chart-pane {
        height: 400px;
    }
}

@media screen and (max-width: 992px) {
    .projections-chart-pane {
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .projections-chart-pane {
        height: 300px;
    }
}

/* ==========================================================================
jVectorMap styles
========================================================================== */
.jvectormap-tip {
    background-color: #e1c1ee;
    opacity: .95;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 6px #333;
    max-width: 250px;
    position: absolute;
    padding: 5px;
    font-size: 12px;
    /*color: #560939;*/
}

.jvectormap-zoomin {
    display: none;
}

.jvectormap-zoomout {
    display: none;
}

.jvectormap-container {
    height: 100%;
    width: 100%;
}

.region-tip-title {
    font-size: 14px;
    font-weight: bold;
}

/* ==========================================================================
helper styles
========================================================================== */
.summary-area {
    padding: 10px;
    background-color: rgba(225, 193, 238, 0.25);
}

/* ==========================================================================
scenario container pane and report area narrowing
========================================================================== */
.scenario-container {
    height: 0;
    width: 0;
    position: absolute;
    z-index: 99; /* Stay on top */
    top: 0;
    left: 0;
    box-shadow: 0 0 10px #888;
    background-color: #fcfcfc;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding: 0;
    /*transition: width 0.1s; !* 0.1 second transition effect to slide in the container *!*/
}

.scenario-container.open {
    height: 100%; /* 100% Full-height */
    padding: 5px; /* Place content 5px from edges */
    width: 35%;
}

.report-area {
    min-height: 800px;
    width: 100%;
    margin-left: 0;
    /*transition: margin-left 0.1s; !* 0.1 second transition effect to slide in the container *!*/
}

.report-area.narrow {
    width: 65%;
    margin-left: 35%;
}

.report-area.narrow .calculating-splash {
    width: 32.5%;
    margin-left: 28.5%;
}

@media screen and (max-width: 768px) {
    .scenario-container.open {
        width: 95%;
    }

    /*do not narrow down the report area on the small screens*/
    .report-area.narrow {
        width: 100%;
        margin-left: 0;
    }
}

.container-accordion-heading {
    /*padding-top: 4px;*/
    white-space: nowrap;
    line-height: normal;
}

.container-accordion-heading:hover,
.container-accordion-heading:focus,
.container-accordion-heading:active {
    outline: none;
    box-shadow: none;
}

.panel-title > a:hover,
.panel-title > a:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

/* ==========================================================================
Slider styles
========================================================================== */
.slider.slider-vertical {
    height: 75px;
}

/*.slider.slider-horizontal{*/
/*width: 75px !important;*/
/*}*/
.slider-selection, .slider-track-high {
    background: #cdd;
}

/* ==========================================================================
Main page styles
========================================================================== */
.ui-tabs-scrollable > .nav-button.right-nav-button:before,
.ui-tabs-scrollable > .nav-button.left-nav-button:before,
.nav-link.ng-binding {
    color: #777;
}
.darkrow_pinkrow > .row > .col-md-12 {
    margin-bottom: -15px
}

.darkrow {
    background: #6e164e;
    border-radius: 8px;
}

.darkrow > h1{
    color : #fff;
    font-size: 18px;
    font-weight: bolder;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.pink-rectangle {
    background: rgba(225, 193, 238, 0.25);
    min-height: 220px;
    border-radius: 8px;
}

.pink-rectangle > h4 {
    color: #6e164e;
    text-align: center;
    padding-top: 5px;
    min-height: 60px;
    font-weight: bold;
}

.pink-rectangle > p {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.pink-row {
    margin-right: 15px;
    margin-left: 15px;
}

.pink-row > .col-md-12 {
    margin-top: 10px;
    background-color: rgba(225, 193, 238, 0.25);
    border-radius: 8px;
}

.pink-row > .col-md-12 > ul {
    margin-top: 10px;
}

.pink-row > .col-md-12 > .col-md-6 {
    padding-bottom: 10px;
}

.pink-row > .col-md-12> .col-md-6 > h1 {
    color: #6e164e;
    height: 28px;
}

.pink-row > .col-md-12> .col-md-6 > h2 {
    color: #6e164e;
    text-align: center;
}

.photo {
    background-color: rgba(225, 193, 238, 0.25);
    padding-bottom: 10px;
    border-radius: 8px;
}

.photo > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
}

.photo > h1 {
    text-align: center;
    color: #6e164e;
}

.photo > p {
    text-align: center;
    font-size: 18px;
    color: #6e164e;
}

.photo > a {
    text-align: center;
    width: 100%;
    display: block;

}
.grey-gpmblock {
    display: flex;
    background-color: #e7e7e7;
    border-radius: 8px;
    text-align: center;
    margin-top: 10px;
    margin-left: 25px;
    width: 30%;
    min-height: 290px;
}

.pink-gpmblock {
    display: flex;
    margin-top: 10px;
    background-color: rgba(225, 193, 238, 0.25);
    border-radius: 8px;
    min-height: 290px;
}

.pink-gpmblock > ul {
    margin-top: 10px;
}

.pink-gpmblock > ul > li {
    padding-bottom: 10px;
}
.grey-block {
    background-color: #e7e7e7;
    border-radius: 8px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;

}

.grey-block > ul {
    padding-top: 10px;
}

.grey-block > ul > li {
    padding-bottom: 10px;
    padding-top: 10px;

}
.pink-block {
    background-color: rgba(225, 193, 238, 0.25);
    border-radius: 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto
}

    /* ==========================================================================
    Generic styles
    ========================================================================== */
.container {
    width: 95%;
    min-width: 320px;
    padding-left: 0;
    padding-right: 0;
}

.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
Font Awesome icons extra style and animation
========================================================================== */

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: 0.5s;
}

.fa-rotate-90 {
    transition: 0.5s;
}

/* ==========================================================================
calculating splash
========================================================================== */
.calculating-splash {
    height: 50%;
    width: 50%;
    position: absolute;
    z-index: 1; /* Sit on top */
    left: 25%;
    margin-left: 0;
    top: 25%;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.05); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    transition: 1.5s;
    text-align: center;
    line-height: 100%;
}

/* The ghost, nudged to maintain perfect centering */
.calculating-splash:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
}

.splash-content {
    display: inline-block;
    vertical-align: middle;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    padding: 5%;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
make sure browsers do not show up-down arrows for numerical inputs
========================================================================== */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

.modal {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-header {
    background: #f0f0f0;
}

.modal-header.bg-warning {
    background: #f0ad4e;
}

/* jhipster-needle-css-add-main JHipster will add new css style */
;